<template>
  <v-form ref="form" @submit.prevent="add">
    <v-layout
      column
      shrink
      :style="{ backgroundColor: color, width: '100%' }"
      px-4
    >
      <v-layout align-center mx-4 py-4>
        <DefaultSubtitle>{{ label }}</DefaultSubtitle>
        <v-spacer></v-spacer>
        <v-flex shrink class="text-center">
          <v-switch
            class="zg-text-control mt-0 pt-0"
            color="expressive_green"
            v-model="field.predefined_records_enabled"
            :label="field.predefined_records_enabled ? 'Dezaktywuj' : 'Aktywuj'"
            :hide-details="true"
            :ripple="false"
            inset
          ></v-switch>
        </v-flex>
      </v-layout>
      <v-layout column v-if="field.predefined_records_enabled">
        <v-layout align-end>
          <v-layout column>
            <LabelTextField
              class="mb-2"
              :value.sync="title"
              label="Tytuł wpisu"
              hide-details
            ></LabelTextField>
            <MaxlengthErrorMessage
              class="mx-4 mb-4"
              :value="title"
              :maxlength="255"
            ></MaxlengthErrorMessage>
            <LabelTextArea
              :rows="2"
              :value.sync="text"
              label="Treść wpisu"
              hide-details
            ></LabelTextArea>
            <MaxlengthErrorMessage
              class="mx-4"
              :value="text"
              :maxlength="2000"
            ></MaxlengthErrorMessage>
            <v-layout align-center class="mx-4 mt-4">
              <DefaultButton
                class="pb-0 mb-2 mt-0 ml-0"
                :disabled="text.length > 2000 || title.length > 255"
                primary
                @click.prevent="add"
                >Dodaj
              </DefaultButton>
            </v-layout>
          </v-layout>
        </v-layout>
        <PredefinedFieldRecordsTable
          :records="records"
          class="mb-4"
          :loading="loading"
          :noPairs="noPairs"
          @remove="handleRemove"
          @edit="handleEdit"
          @pair="handlePair"
        ></PredefinedFieldRecordsTable>
      </v-layout>
    </v-layout>
    <ConfirmDialog
      v-if="showDeleteDialog"
      :value.sync="showDeleteDialog"
      :positiveAction="remove"
      title="Usuń wpis"
      text="Czy jesteś pewny, że chcesz usunąć ten wpis?"
    ></ConfirmDialog>
    <PredefinedEditPopup
      v-if="showEditDialog"
      :value.sync="showEditDialog"
      :record.sync="selectedRecord"
      :field="field"
      :cardType="cardType"
      @success="(message) => fetch(message)"
    >
    </PredefinedEditPopup>
    <PredefinedPairPopup
      v-if="showPairPopup"
      :value.sync="showPairPopup"
      :predefined="selectedRecord"
      :cardType="cardType"
      @success="handlePairsSaveSuccess"
    ></PredefinedPairPopup>
  </v-form>
</template>

<script>
import PredefinedService from "@/services/predefined.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import Validators from "@/mixins/Validators";
import DoctorReloadMixin from "@/mixins/DoctorReloadMixin";

import URO_NAMES from "@/constants/patientcard/uro";
import PHY_NAMES from "@/constants/patientcard/physio";
import PED_NAMES from "@/constants/patientcard/pediatric";

export default {
  mixins: [ProxyCRUDMixin, Validators, DoctorReloadMixin],
  props: {
    field: {
      default: () => ({
        label: "Test",
        predefined_records_enabled: false,
      }),
    },
    color: {
      default: "white",
    },
    noPairs: {
      type: Boolean,
      default: false,
    },
    cardType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pagination: {
        sortBy: "title",
        descending: true,
      },
      showEditDialog: false,
      selectedRecord: null,
      showDeleteDialog: false,
      showPairPopup: false,
      records: [],
      text: "",
      title: "",
    };
  },
  watch: {
    field: {
      handler() {
        if (this.field.predefined_records_enabled) {
          this.fetch();
        }
      },
      deep: true,
    },
  },
  computed: {
    label() {
      if (this.cardType == 0) {
        return PHY_NAMES[this.field.name] || this.field.label;
      } else if (this.cardType == 1) {
        return URO_NAMES[this.field.name] || this.field.label;
      } else {
        return PED_NAMES[this.field.name] || this.field.label;
      }
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    LabelTextArea: () => import("@/components/LabelTextArea"),
    LabelTextField: () => import("@/components/LabelTextField"),
    ConfirmDialog: () => import("@/components/popups/ConfirmDialog"),
    PredefinedFieldRecordsTable: () =>
      import("@/components/tables/PredefinedFieldRecordsTable"),
    PredefinedPairPopup: () =>
      import("@/components/popups/predefined/PredefinedPairPopup"),
    PredefinedEditPopup: () =>
      import("@/components/popups/predefined/PredefinedEditPopup"),
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
  },
  methods: {
    handleSelectedTemplate(template) {
      this.title = template.title;
      this.text = template.text;
    },
    handlePairsSaveSuccess(message) {
      this.fetch(message);
    },
    fetch(message) {
      this.beforeRequest();
      PredefinedService.getPredefinedRecords(this.field.name, {
        card_type: this.cardType,
        ...this.pagination,
      })
        .then((x) => {
          this.records = x.results;
          if (message) {
            this.handleSuccess(message);
          } else {
            this.reset();
          }
        })
        .catch(() => {
          this.handleError("Coś poszło nie tak.");
        });
    },
    add() {
      if (this.title.length == 0) {
        this.handleError("Tytuł nie może być pusty.");
        return;
      } else if (this.text.length == 0) {
        this.handleError("Treść nie może być pusta.");
        return;
      }
      this.beforeRequest();
      PredefinedService.addPredefinedRecord(
        {
          field: this.field.name,
          title: this.title,
          text: this.text,
        },
        {
          card_type: this.cardType,
          ...this.pagination,
        }
      )
        .then((x) => {
          this.title = "";
          this.text = "";
          this.$refs.form.resetValidation();
          this.records = x.results;
          this.handleSuccess("Dodano pomyślnie nowy wpis.");
        })
        .catch((error) => {
          console.log(error);
          this.handleError("Coś poszło nie tak.");
        });
    },
    remove() {
      this.beforeRequest();
      PredefinedService.removePredefinedRecord(this.selectedRecord, {
        card_type: this.cardType,
        ...this.pagination,
      })
        .then((x) => {
          this.records = x.results;
          this.handleSuccess("Pomyślnie usunięto wpis.");
        })
        .catch((error) => {
          console.log(error);
          this.handleError("Coś poszło nie tak.");
        });
    },
    handleEdit(record) {
      this.selectedRecord = JSON.parse(JSON.stringify(record));
      this.showEditDialog = true;
    },
    handleRemove(record) {
      this.selectedRecord = JSON.parse(JSON.stringify(record));
      this.showDeleteDialog = true;
    },
    handlePair(record) {
      this.selectedRecord = JSON.parse(JSON.stringify(record));
      this.showPairPopup = true;
    },
    sort() {
      this.pagination.descending = !this.pagination.descending;
      this.fetch();
    },
    reloadData() {
      if (!this.field.predefined_records_enabled) {
        return;
      }
      this.fetch();
    },
  },
  created() {
    if (!this.field.predefined_records_enabled) {
      return;
    }
    this.fetch();
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>